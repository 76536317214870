@charset "UTF-8";
@import "sass-vars.module.scss";
@import "bootstrap.scss";
@import "icons.scss";

// IMPORTANT NOTICE
// css variables commented with the value "undefined" are not unused !
// they are there to remember that they can be overriden by configuration
// so dont remove them unless you know what you do
:root {
  // don't forget to check contrast against gray background (read only inputs notably)
  // These colors meet the WCAG AA-level contrast ratio 4.5:1,
  // except for green and orange that have only 3:1, but that is acceptable if used for UI elements only,
  // and the yellow and gray that have their text in button set to the white color.
  --color-blue: #006EDC;
  --color-green: #3EA843;
  --color-orange: #EF7000;
  --color-purple: #A451CD;
  --color-red: #EE0000;
  --color-yellow: #FFCC00;

  --color-states-blue: #005FBE;
  --color-states-green: #338A37;
  --color-states-orange: #D16200;
  --color-states-purple: #8C45AF;
  --color-states-red: #D00000;
  --color-states-yellow: #E1B400;
  --color-states-gray: var(--color-gray-darker);

  --color-bg-blue: hsl(207, 48%, 90%);
  --color-bg-green: hsl(120, 61%, 91%);
  --color-bg-orange: hsl(35, 100%, 90%);
  --color-bg-red: hsl(356, 100%, 94%);

  --color-white: hsl(0, 0%, 100%);
  --color-gray-lightest: hsl(0, 0%, 96%);
  --color-gray-lighter: hsl(0, 0%, 90%);
  --color-gray: hsl(0, 0%, 80%);
  --color-gray-darker: hsl(0, 0%, 70%);
  --color-gray-darkest: hsl(0, 0%, 60%);
  --color-black-lightest: hsl(0, 0%, 46%); // the lightest acceptable for WCAG AA requirement
  --color-black-lighter: hsl(0, 0%, 23%);
  --color-black: hsl(0, 0%, 0%);

  --btn-blue: var(--color-blue);
  --btn-green: var(--color-green);
  --btn-orange: var(--color-orange);
  --btn-purple: var(--color-purple);
  --btn-red: var(--color-red);
  --btn-yellow: var(--color-yellow);
  --btn-gray: var(--color-gray);

  --btn-blue-states-color: var(--color-states-blue);
  --btn-green-states-color: var(--color-states-green);
  --btn-orange-states-color: var(--color-states-orange);
  --btn-purple-states-color: var(--color-states-purple);
  --btn-red-states-color: var(--color-states-red);
  --btn-yellow-states-color: var(--color-states-yellow);
  --btn-gray-states-color: var(--color-states-gray);

  --primary-color: var(--color-blue);
  --primary-states-color: var(--color-states-blue);
  --primary-background-color: var(--color-bg-blue);
  --info-color: var(--color-blue);
  --info-states-color: var(--color-states-blue);
  --info-background-color: var(--color-bg-blue);
  --success-color: var(--color-green);
  --success-states-color: var(--color-states-green);
  --success-background-color: var(--color-bg-green);
  --warning-color: var(--color-orange);
  --warning-states-color: var(--color-states-orange);
  --warning-background-color: var(--color-bg-orange);
  --danger-color: var(--color-red);
  --danger-states-color: var(--color-states-red);
  --danger-background-color: var(--color-bg-red);
  --muted-color: var(--color-gray-darkest);
  --muted-background-color: var(--color-gray-lightest);
  --muted-border-color: var(--color-gray-lighter);

  --map-marker-color-blue: var(--color-states-blue);
  --map-marker-color-green: var(--btn-green);
  --map-marker-color-orange: var(--btn-orange);
  --map-marker-color-purple: var(--btn-purple);
  --map-marker-color-red: var(--btn-red);
  --map-marker-color-yellow: var(--btn-yellow);

  --text-color: var(--color-black);
  --link-color: var(--color-blue);
  --font-family: "Inter", -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  --line-height: 1.5; // min required for accessibility
  --body-background: #f3f3f3;
  --border-radius-base: 4px;
  --block-spacing: 15px;

  --input-placeholder-color: var(--color-black-lightest);
  --input-label-focus-color: var(--text-color);
  --input-border-color: var(--color-gray-lighter);
  // true black here for highest accessibility contrast
  --input-border-focus-color: #000;
  --input-v-padding: 8px;
  --input-h-padding: 12px;
  --input-addon-h-padding: 6px;

  // true black here for highest accessibility contrast
  --accessibility-outline: 3px auto #000;
  --accessibility-outline-offset: 3px;
  --accessibility-outline-offset-inset: -2px;
  --accessibility-outline-nav-control: 3px auto var(--nav-controls-text-color);

  // the min size value to reach AA accessibility level
  --wcag-aa-min-size: 24px;

  --font-size-base: 1.4rem;
  --font-size-h1: 32px;
  --font-size-h2: 28px;
  --font-size-h3: var(--wcag-aa-min-size);
  --font-size-h4: 20px;
  --font-size-h5: 17px;
  --font-size-h6: 14px;

  --icn-xxxs: 10px;
  --icn-xxs: 12px;
  --icn-xs: 16px;
  --icn-sm: 20px;
  --icn-md: var(--wcag-aa-min-size);
  --icn-lg: 28px;
  --icn-xl: 32px;
  --icn-xxl: 36px;

  --kpi-icon-font-size: 3rem;
  --kpi-number-font-size: 2rem;
  --kpi-text-font-size: 1.5rem;
  --container-padding: var(--block-spacing);
  --category-label-font-size: 1.2rem;
  --table-header-font-size: 1.3rem;
  --table-inner-padding-h: 8px;
  --table-inner-padding: 10.5px var(--table-inner-padding-h);
  --panel-margin-bottom: 0px;
  --row-background-hover-color: var(--muted-background-color);
  // --notification-background-color: undefined; // unused for now

  --overlay-background: rgba(24, 36, 49, 0.55);
  --authentication-background: linear-gradient(145deg, #182431 0, #8a9aaa) center / cover no-repeat;

  --form-group-margin-bottom: var(--block-spacing);
  --form-group-label-font-size: 1.3rem;

  --btn-lg-padding: 4px 10px;
  --btn-sm-padding: 3px 5px;
  --btn-xs-padding: 2px 3px;
  --btns-margin: 5px;
  --btn-border-radius: 4px;
  --btn-round-border-radius: 30px;

  --modal-border: none;
  --modal-border-radius: 6px;

  --card-background: var(--color-white);
  --card-box-shadow: rgba(26, 26, 26, 0.07) 0px 1px 0px 0px;
  --card-border: 1px solid var(--card-border-color);
  --card-border-color: var(--color-gray-lighter);
  --card-border-radius: var(--border-radius-base);
  --card-margin-bottom: var(--block-spacing);
  --card-padding-lateral: var(--block-spacing);
  --card-padding-axial: var(--block-spacing);
  --card-header-btn-min-height: 30px;
  // --card-header-text-color: undefined;
  --card-header-padding: var(--card-padding-axial) var(--card-padding-lateral);
  --card-header-plus-content-padding-top: 0;
  --card-content-padding: var(--card-padding-axial) var(--card-padding-lateral);
  --card-footer-padding: var(--card-padding-axial) var(--card-padding-lateral);
  --card-title-font-size: var(--font-size-h4);
  // --card-title-text-transform: undefined;
  --card-title-font-weight: bold;
  --stats-card-min-height: 70px;

  --app-controls-background: var(--color-white);
  --app-controls-border-bottom: var(--card-border);
  --app-controls-height: 60px;
  --app-controls-dropdown-border-radius: 10px;
  --app-controls-dropdown-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  --app-controls-dropdown-font-size: var(--font-size-base);
  --app-controls-dropdown-font-weight: normal;
  --app-controls-dropdown-item-height: 34px;
  --app-controls-dropdown-icon-size: var(--wcag-aa-min-size);
  --app-controls-dropdown-icon-margin: 15px;
  --app-controls-search-form-border-radius: var(--border-radius-base);
  --app-controls-items-color: var(--color-black-lighter);

  --nav-controls-background: #282828;
  // --nav-controls-border-right: var(--app-controls-border-bottom);
  --nav-controls-category-font-size: 1.2rem;
  --nav-controls-category-subcontrol-font-size: var(--font-size-base);
  --nav-controls-category-font-weight: 600;
  --nav-controls-category-text-transform: uppercase;
  --nav-controls-item-font-size: var(--font-size-base);
  --nav-controls-item-min-height: 38px;
  --nav-controls-width: 260px;
  --nav-controls-search-icon-padding-responsive: 0 16px;
  --nav-controls-link-border-radius: var(--border-radius-base);
  --nav-controls-link-icon-font-size: 2.8rem;
  --nav-controls-link-icon-font-size-responsive: 2.2rem;
  --nav-controls-link-margin-bottom: 5px;
  --nav-controls-link-margin-h: 15px;
  --nav-controls-link-margin: 0 var(--nav-controls-link-margin-h) var(--nav-controls-link-margin-bottom);
  --nav-controls-link-padding-h: 10px;
  --nav-controls-link-padding-v: 10px;
  --nav-controls-link-padding: var(--nav-controls-link-padding-v) var(--nav-controls-link-padding-h);
  // --nav-controls-link-active-icon-color: undefined;
  // --nav-controls-link-active-text-color: undefined;
  // --nav-controls-link-hover-emphasis-border-color: undefined;
  --nav-controls-link-active-background: rgba(255, 255, 255, 0.23);
  --nav-controls-nav-hover: hsla(0, 0%, 100%, 0.13);
  --nav-controls-lateral-borders: none;
  --nav-controls-logo-partner-or-tenant-background: var(--nav-controls-background);
  --nav-controls-logo-partner-or-tenant-height: var(--app-controls-height);
  --nav-controls-logo-tenant-background: var(--nav-controls-background);
  --nav-controls-logo-tenant-height: var(--app-controls-height);
  --nav-controls-text-color: var(--color-white);
  --nav-controls-left-element-margin-right: 15px;
  --nav-controls-line-h-left: 50%;
  --nav-controls-line-v-left: 25px;

  // --scrollbar-browser-width: 17px;
  --scrollbar-theme-size: 8px;
  --scrollbar-theme-track-background: var(--color-gray-lighter);
  --scrollbar-theme-thumb-background: var(--color-gray-darker);
  --scrollbar-theme-thumb-border: 1px solid var(--color-gray-lighter);
  --scrollbar-theme-thumb-border-radius: var(--border-radius-base);
  --scrollbar-theme-thumb-hover-background: var(--color-gray-darkest);

  --input-min-height: 36px;
  // We set this var to auto height value given by browsers (this is the default value for Chrome)
  // so that auto resize does not show a useless vertical scrollbar
  // It's a shame we cannot set it to the same value as input.
  // This value is used in FormInput so watch out if you ever change it.
  --textarea-min-height: 38px;

  --control-btn-hw: 30px;
  --control-btn-font-size: 1.2rem;

  // bp stands for "break-point"
  --bp-width-screen-xxs: #{$screen-xxs};
  --bp-width-screen-xs: #{$screen-xs};
  --bp-width-screen-xs-sm: #{$screen-xs-sm};
  --bp-width-screen-sm: #{$screen-sm};
  --bp-width-screen-md: #{$screen-md};
  --bp-width-screen-lg: #{$screen-lg};
  --bp-width-screen-xl: #{$screen-xl};
  --bp-width-screen-xxl: #{$screen-xxl};
}

@import "global.scss";
@import "theme-groups.scss";
@import "row-col-grid.scss";
@import "checkbox-radio.scss";
@import "switch.scss";
@import "chartist.scss";
@import "datetime-picker.scss";
@import "page-frame.scss";
@import "card.scss";
@import "table.scss";
@import "notification.scss";
@import "slider.scss";
@import "buttons.scss";
@import "typography.scss";
@import "utilities.scss";
@import "form-input.scss";
@import "form-input-rich-text.scss";
@import "form-select.scss";
@import "document.scss";
@import "leaflet.scss";
@import "modal.scss";
@import "tooltip.scss";
@import "datasheet.scss";
@import "charts.scss";
@import "panel-container.scss";
@import "authentification.scss";
@import "highlight.scss";
@import "documentation.scss";
@import "entity-step.scss";
@import "entity-workflow.scss";
@import "note.scss";
@import "calendar.scss";
@import "carousel.scss";
@import "virtual-keyboard.scss";
@import "specific.scss"; // should be among the last so it can override global declarations
@import "print.scss"; // should be among the last so it can override global declarations
@import "rtl.scss"; // should be the last so it can override everything